/* eslint-disable react-hooks/rules-of-hooks */
import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { ROLES } from 'src/utils/utils';
import { paths } from '../paths';
// import SansthaRegistrationMain from 'src/pages/HSSF/sanstha-registration/sanstha-registration-main';

const Dashboard = lazy(() => import('src/pages/HSSF/dashboard/Dashboard'));

// user
const UserManagementMain = lazy(() =>
  import('src/pages/HSSF/user/user-management/user-management-main')
);
const UserRegistrationMain = lazy(() =>
  import('src/pages/HSSF/user/user-registration/user-registration-main')
);
const UserDetailsMain = lazy(() => import('src/pages/HSSF/user/user-management/user-details-main'));

// ! new - start

// Fair
const FairMain = lazy(() => import('src/pages/HSSF/fair/fair-main'));
const FairRegistrationMain = lazy(() => import('src/pages/HSSF/fair/fair-registration-main'));

// Team-Management
const TeamManagementMain = lazy(() =>
  import('src/pages/HSSF/team-management/team-management-main')
);

// prant
const PrantMain = lazy(() => import('src/pages/HSSF/prant/prant-main'));
// vibhag
const VibhagMain = lazy(() => import('src/pages/HSSF/vibhag/vibhag-main'));
// jilla
const JillaMain = lazy(() => import('src/pages/HSSF/jilla/jilla-main'));
// taluka
const TalukaMain = lazy(() => import('src/pages/HSSF/taluka/taluka-main'));

// sanstha - registration
// const CorporateMain = lazy(() =>
//   import('src/pages/HSSF/unused-sanstha-registration/corporate/corporate-main')
// );
// const NGOMain = lazy(() => import('src/pages/HSSF/unused-sanstha-registration/ngo/ngo-main'));
// const EducationalMain = lazy(() =>
//   import('src/pages/HSSF/unused-sanstha-registration/educational/educational-main')
// );
// const ReligiousMain = lazy(() =>
//   import('src/pages/HSSF/unused-sanstha-registration/religious/religious-main')
// );

const InstitutionRegistrationMain = lazy(() =>
  import('src/pages/HSSF/Institution/Institution-registration-main')
);
const InstitutionManagementMain = lazy(() =>
  import('src/pages/HSSF/Institution/Institution-management-main')
);

// Event
const CreateEventMain = lazy(() => import('src/pages/HSSF/event/create-event-main'));
const RegistrationEventMain = lazy(() => import('src/pages/HSSF/event/event-registration-main'));
const EventDetailsMain = lazy(() => import('src/pages/HSSF/event/event-details-main'));
// Report
const GenerateReportMain = lazy(() => import('src/pages/HSSF/report/generate-report-main'));
const ImageAndVideoMain = lazy(() => import('src/pages/HSSF/report/image-and-video-main'));
const ReportViewMain = lazy(() => import('src/pages/HSSF/report/report-view-main'));

const EventManagement = lazy(() =>
  import('src/sections/HSSF/events/event-management/event-management')
);
// ! new - end

// const replaceRoute = getHomeRouteForLoggedInUser(currentUser()?.role);
// console.log('replaceRoute: ', replaceRoute);

export const hssfRoutes = [
  {
    path: paths.hssfPaths.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <RoleBasedGuard
            hasContent
            roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
          >
            <Dashboard />
          </RoleBasedGuard>
        ),
        // element: <Navigate to={getHomeRouteForLoggedInUser(currentUser()?.role)} replace />,
        index: true,
      },
      // ! new - start
      // Fair-Components
      {
        path: 'fair',
        children: [
          {
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <FairMain />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'registration',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <FairRegistrationMain />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      // Team-Management
      {
        path: paths.hssfPaths.teamManagement.root,
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.superAdmin]}>
            <TeamManagementMain />
          </RoleBasedGuard>
        ),
      },
      // State
      {
        path: paths.hssfPaths.prant.root,
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.prant]}>
            <PrantMain />
          </RoleBasedGuard>
        ),
      },
      // vibhag
      {
        path: paths.hssfPaths.vibhag.root,
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag]}>
            <VibhagMain />
          </RoleBasedGuard>
        ),
      },
      // District
      {
        path: paths.hssfPaths.jilla.root,
        element: (
          <RoleBasedGuard
            hasContent
            roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla]}
          >
            <JillaMain />
          </RoleBasedGuard>
        ),
      },
      // taluka
      {
        path: paths.hssfPaths.taluka.root,
        element: (
          <RoleBasedGuard
            hasContent
            roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
          >
            <TalukaMain />
          </RoleBasedGuard>
        ),
      },

      {
        path: 'user',
        children: [
          // ! for
          {
            element: <Navigate to={paths.hssfPaths.user.management.root} replace />,
            index: true,
          },
          {
            path: 'registration',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <UserRegistrationMain />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'management',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <UserManagementMain />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'management/:id',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <UserDetailsMain />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      // sanstha - registration

      {
        path: 'institution',
        children: [
          // ! for
          {
            element: <Navigate to={paths.hssfPaths.institution.management.root} replace />,
            index: true,
          },
          {
            path: 'management',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <InstitutionManagementMain />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'registration',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <InstitutionRegistrationMain />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      // PROGRAMME
      {
        path: 'event',
        children: [
          {
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <EventManagement />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'create',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <CreateEventMain />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'registration',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <RegistrationEventMain />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'details',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <EventDetailsMain />
              </RoleBasedGuard>
            ),
          },
          // {
          //   path: 'report',
          //   element: (
          //     <RoleBasedGuard
          //       hasContent
          //       roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
          //     >
          //       <GenerateReportMain />
          //     </RoleBasedGuard>
          //   ),
          // },
        ],
      },

      // PROGRAMME
      {
        path: 'report',
        children: [
          {
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <ReportViewMain />
              </RoleBasedGuard>
            ),
            index: true,
          },

          {
            path: 'generate',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <GenerateReportMain />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'image-video',
            element: (
              <RoleBasedGuard
                hasContent
                roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
              >
                <ImageAndVideoMain />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      // event_management
      // {
      //   path: paths.hssfPaths.event.root,
      //   element: (
      //     <RoleBasedGuard
      //       hasContent
      //       roles={[ROLES.superAdmin, ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
      //     >
      //       <EventManagement />
      //     </RoleBasedGuard>
      //   ),
      // },

      // {
      //   path: 'sanstha-registration',
      //   children: [
      //     // ! for
      //     {
      //       element: <Navigate to={paths.hssfPaths.institution.educational_reg} replace />,
      //       index: true,
      //     },
      //     {
      //       path: 'educational',
      //       element: (
      //         <RoleBasedGuard
      //           hasContent
      //           roles={[ROLES.superAdmin,ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
      //         >
      //           <EducationalMain />
      //         </RoleBasedGuard>
      //       ),
      //     },
      //     {
      //       path: 'ngo',
      //       element: (
      //         <RoleBasedGuard
      //           hasContent
      //           roles={[ROLES.superAdmin,ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
      //         >
      //           <NGOMain />
      //         </RoleBasedGuard>
      //       ),
      //     },
      //     {
      //       path: 'corporate',
      //       element: (
      //         <RoleBasedGuard
      //           hasContent
      //           roles={[ROLES.superAdmin,ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
      //         >
      //           <CorporateMain />
      //         </RoleBasedGuard>
      //       ),
      //     },
      //     {
      //       path: 'religious',
      //       element: (
      //         <RoleBasedGuard
      //           hasContent
      //           roles={[ROLES.superAdmin,ROLES.prant, ROLES.vibhag, ROLES.jilla, ROLES.taluka]}
      //         >
      //           <ReligiousMain />
      //         </RoleBasedGuard>
      //       ),
      //     },
      //   ],
      // },
      // ! new - end
    ],
  },
];
