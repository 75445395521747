import { format } from 'date-fns';
import { paths } from 'src/routes/paths';

export const ROLES = {
  superAdmin: 'super-admin',
  prant: 'prant-admin',
  vibhag: 'vibhag-admin',
  jilla: 'jilla-user',
  taluka: 'taluka-user',
};

export const UserDetails = [
  {
    email: 'superadmin@gmail.com',
    user_name: 'Super Admin ',
    password: 'User@123',
    value: 'prant',
    role: 'super-admin',
    aayam: 'super-admin',
    user_location: {
      prant: 'gujarat',
      vibhag: null,
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'sachivprant@gmail.com',
    user_name: 'Sachiv - State',
    password: 'User@123',
    value: 'prant',
    role: 'prant-admin',
    aayam: 'sachiv',
    user_location: {
      prant: 'gujarat',
      vibhag: null,
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'karyalayaprant@gmail.com',
    user_name: 'Office - State',
    password: 'User@123',
    value: 'prant',
    role: 'prant-admin',
    aayam: 'karyalaya',
    user_location: {
      prant: 'gujarat',
      vibhag: null,
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'sayojakvibhag@gmail.com',
    user_name: 'Sayojak - Vibhag',
    password: 'User@123',
    value: 'vibhag',
    role: 'vibhag-admin',
    aayam: 'sayojak',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'pracharjaheratvibhag@gmail.com',
    user_name: 'Prachar Jaherat - Vibhag',
    password: 'User@123',
    value: 'vibhag',
    role: 'vibhag-admin',
    aayam: 'prachar_jaherat',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: null,
      taluka: null,
    },
  },
  {
    email: 'sayojakjilla@gmail.com',
    user_name: 'Sayojak - District',
    password: 'User@123',
    value: 'jilla',
    role: 'jilla-user',
    aayam: 'sayojak',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: null,
    },
  },
  {
    email: 'corporatesamparkjilla@gmail.com',
    user_name: 'Corporate Houses - District',
    password: 'User@123',
    value: 'jilla',
    role: 'jilla-user',
    aayam: 'corporate_sampark',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: null,
    },
  },
  {
    email: 'itjilla@gmail.com',
    user_name: 'IT - District',
    password: 'User@123',
    value: 'jilla',
    role: 'jilla-user',
    aayam: 'it',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: null,
    },
  },
  {
    email: 'sayojaktaluka@gmail.com',
    user_name: 'Sayojak - Taluka',
    password: 'User@123',
    value: 'taluka',
    role: 'taluka-user',
    aayam: 'sayojak',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: 'karnavati_east',
    },
  },
  {
    email: 'parivarkshetrataluka@gmail.com',
    user_name: 'Family Organizations - Taluka',
    password: 'User@123',
    value: 'taluka',
    role: 'taluka-user',
    aayam: 'parivar_kshetra',
    user_location: {
      prant: 'gujarat',
      vibhag: 'navsari',
      jilla: 'vadodra',
      taluka: 'karnavati_east',
    },
  },
];
// who can access all module according roles
export const SuperAayamRoles = {
  sachiv: 'sachiv',
  sahsachiv: 'sahsachiv',
  sayojak: 'sayojak',
  sahsyojak: 'sahsyojak',
  super_admin: 'super-admin',
  karyalaya: 'karyalaya',
  it: 'it',
};

// who can generate report according roles
export const generateReportAayamRoles = {
  karyalaya: 'karyalaya',
  it: 'it',
};

export const currentUser = () => JSON.parse(localStorage.getItem('user'));

//  ! get-homeRoute for logged-In-User START
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === ROLES.prant) return paths.hssfPaths.prant.root; // State
  if (userRole === ROLES.vibhag) return paths.hssfPaths.vibhag.root; // vibhag
  if (userRole === ROLES.jilla) return paths.hssfPaths.jilla.root; // District
  if (userRole === ROLES.taluka) return paths.hssfPaths.taluka.root; // taluka
  return paths.auth.jwt.login;
};

// fair get year start
export const getFairYear = () => {
  const currentYear = new Date().getFullYear();
  // const viewYear = currentYear - (currentYear % 5);
  const viewYear = Math.ceil(currentYear / 5) * 5;
  // console.log('current fair year :', viewYear);
  return viewYear;
};

// ! get-data for API call
export const useCurrentDashboardData = () => {
  // get - local - data
  const getData = JSON.parse(localStorage.getItem('hssf_dashboard'));

  // set - local - data
  const setData = (value) => {
    const setLocalData = { ...getData, ...value };
    localStorage.setItem('hssf_dashboard', JSON.stringify(setLocalData));
  };

  return {
    setData,
    getData,
  };
};

// Interest : -----
export const interests_options = [
  {
    value: 'Conserve Forests & Protect WildLife',
    label: 'Conserve Forests & Protect WildLife',
    guj_label: 'વન અને વન્યજીવન સંરક્ષણ',
  },
  { value: 'Preserve Ecology', label: 'Preserve Ecology', guj_label: 'જીવસૃષ્ટિ સંતુલન' },
  { value: 'Sustain Environment', label: 'Sustain Environment', guj_label: 'પર્યાવરણ સંરક્ષણ' },
  {
    value: 'Inculcate Family & Human Values',
    label: 'Inculcate Family & Human Values',
    guj_label: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
  },
  {
    value: 'Foster Women`s Honour',
    label: 'Foster Women`s Honour',
    guj_label: 'નારી સન્માન અને સંરક્ષણ',
  },
  { value: 'Instill Patriotism', label: 'Instill Patriotism', guj_label: 'રાષ્ટ્રભક્તિ જાગરણ' },
];

// Section Types : -----
export const aayam_types_options = [
  { value: 'dharmik', label: 'Dharmik Institutions', guj_label: 'ધાર્મિક (મઠ મંદિર)' },
  {
    value: 'shikshan_sanstha_sampark',
    label: 'Educational Institutions',
    guj_label: 'શૈક્ષણિક સંસ્થા સંપર્ક',
  },
  {
    value: 'samajik_sanstha_sampark',
    label: 'Socialist (NGOs)',
    guj_label: 'સામાજિક સંસ્થા (NGO/ સેવા) સંપર્ક',
  },
  { value: 'yuva_shakti', label: 'Youth Wings', guj_label: 'યુવા શક્તિ' },
  { value: 'nari_shakti', label: 'Female Wings', guj_label: 'નારી શક્તિ' },
  { value: 'parivar_kshetra', label: 'Family Organizations', guj_label: 'પરિવાર ક્ષેત્ર' },
  { value: 'corporate_sampark', label: 'Corporate Houses', guj_label: 'કોર્પોરેટ સંપર્ક' },
  { value: 'prachar_jaherat', label: 'Media / Social Media', guj_label: 'પ્રચાર - પ્રસાર' },
  { value: 'karyalaya', label: 'Office', guj_label: 'કાર્યાલય' },
  { value: 'it', label: 'IT', guj_label: 'IT' },
];

// Person`s Type : -----
export const person_types_option = [
  { value: 'individual', label: 'Individual', guj_label: 'વ્યક્તિગત' },
  { value: 'organization', label: 'Organization', guj_label: 'સંસ્થા' },
];

// working-time : -----
export const working_times_option = [
  { value: 'daily', label: 'Daily', guj_label: 'દૈનિક' },
  { value: 'weekly', label: 'Weekly', guj_label: 'સાપ્તાહિક' },
  { value: 'monthly', label: 'Monthly', guj_label: 'માસિક' },
];

// working-hours : -----
export const working_hours_option = [
  { value: '1-3 hrs', label: '1-3 hrs', guj_label: '1-3 કલાક' },
  { value: '3-5 hrs', label: '3-5 hrs', guj_label: '3-5 કલાક' },
  { value: 'More than 5 hrs', label: 'More than 5 hrs', guj_label: '5 કલાકથી વધુ' },
];

// Section Types : -----
export const organization_types_options = [
  { value: 'dharmik', label: 'Dharmik Institutions', guj_label: 'ધાર્મિક (મઠ મંદિર)' },
  { value: 'ngo', label: 'NGO', guj_label: 'NGO' },
  { value: 'corporate', label: 'Corporate', guj_label: 'કોર્પોરેટ' },
  { value: 'shaikshanik', label: 'Educational Institutions', guj_label: 'શૈક્ષણિક સંસ્થા સંપર્ક' },
  {
    value: 'parivar_institution',
    label: 'Parivar Institution',
    guj_label: 'પરિવાર સંસ્થા',
  },
  {
    value: 'jatigat_institution',
    label: 'Jatigat Institution',
    guj_label: 'જાતિગત સંસ્થા',
  },
];

export const customCombineKeyFunction = (arrayData, combineKeys, gujCombineKeys) =>
  arrayData.map((item) => {
    const combinedData = combineKeys
      ? combineKeys
          .map((key) => item[key])
          .filter((val) => val !== null && val !== undefined && val !== '')
          .join(' / ')
      : '';
    const gujCombineData = gujCombineKeys
      ? gujCombineKeys
          .map((key) => item[key])
          .filter((val) => val !== null && val !== undefined && val !== '')
          .join(' / ')
      : '';

    return { ...item, combine_data_key: combinedData, guj_combine_data_key: gujCombineData };
  });

// date range view for dd/mm/yyyy
export const dateRangeView = (startDate, endDate) =>
  startDate &&
  (format(startDate, 'dd/MM/yyyy') === (endDate && format(endDate, 'dd/MM/yyyy'))
    ? format(startDate, 'dd/MM/yyyy')
    : `${format(startDate, 'dd/MM/yyyy')}${endDate ? '  -  ' : ''}${
        endDate ? format(endDate, 'dd/MM/yyyy') : ''
      }`);
